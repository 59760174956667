

import { ChatWebpush } from "../common/chat_webpush";

$(function () {
  if ($(".chat").length <= 0) {
    return;
  }

  let chatWebPush = new ChatWebpush(gon.service_worker_scope, ".subscription-button", gon.channel_id)
  chatWebPush.init();

});
