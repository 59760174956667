export {
  getFirebaseConfig
}

//dev
const devConfig = {
  apiKey: "AIzaSyCuIzvGhQ4hSd6TOdVr48ZVsPH4Wv-5ZxA",
  authDomain: "online-learning-393907.firebaseapp.com",
  projectId: "online-learning-393907",
  storageBucket: "online-learning-393907.appspot.com",
  messagingSenderId: "255648768577",
  appId: "1:255648768577:web:2595057ebc69281466dcc0"
};

//pro
const proConfig = {
  apiKey: "AIzaSyBsLLTRRiSADww4h7AC5c0-pc2aoNVmBvc",
  authDomain: "elearning-sgk-p-sugi-2402.firebaseapp.com",
  projectId: "elearning-sgk-p-sugi-2402",
  storageBucket: "elearning-sgk-p-sugi-2402.appspot.com",
  messagingSenderId: "23844646629",
  appId: "1:23844646629:web:d0f3f5205852a6ad126e0c"
};

const getFirebaseConfig = () => {

  if (gon.env == "production") {
    return proConfig;
  } else {
    return devConfig;
  }
}