
import {
  MailLoginAndAppLogin,
} from "./firebase"

import {
  check_password_comfirm,
  check_password_require,
  check_weak_pasword,
  check_password_rule,
} from "./validates"

$(function () {
  if ($(".registration.new").length <= 0 &&
    $(".registration.create").length <= 0) {
    console.log("新規登録ページではありません。");
    return;
  }
  console.log("新規登録ページの初期化処理を開始します。");
  check_password_comfirm();
  check_password_require();
  check_weak_pasword();
  check_password_rule();
  console.log("新規登録ページの初期化処理が完了しました。");

  $("#create_contractor_form").on("ajax:success", async function (event) {
    console.log("フォームの送信に成功しました。");
    var data = event.detail[0];
    console.log("サーバーからのレスポンス:", data);
    if(data['mode'] != "create_user_complete"){
      console.log("ユーザー作成が完了していないため、Firebase認証をスキップします。");
      return;
    }
    console.log("ユーザー作成が完了しました。Firebase認証を開始します。");
    try {
      await MailLoginAndAppLogin();
      console.log("Firebase認証が成功しました。");
    } catch (error) {
      console.error("Firebase認証に失敗しました。", error);
    }
  });
});

