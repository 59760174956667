export {
  translate_from_ja_to_en,
}

function translate_from_ja_to_en(jaText) {

  let fromLang = 'ja'
  let toLang = 'en'
  let apiKey = gon.cloud_translation_api_key
  
  // 翻訳
  const URL = "https://translation.googleapis.com/language/translate/v2?key="+apiKey+
      "&q="+encodeURI(jaText)+"&source="+fromLang+"&target="+toLang
  let xhr = new XMLHttpRequest()
  xhr.open('POST', [URL], false)
  xhr.send();
  if (xhr.status === 200) {
      const res = JSON.parse(xhr.responseText); 
      return res["data"]["translations"][0]["translatedText"]
  }
  
  return "error"
}