export {
  init_disp_messages,
  init_pager,
  go_to_top,
  go_to_bottom,
  scroll_to_message,
  scroll_to_element,
  scroll_to_reply,
  get_parent_message_dom_from_messages,
  get_parent_message_dom_from_message_id,
  get_message_dom,
  get_message_reply_area_dom,
  get_message_reply_form_dom,
  setting_translate_event,
  setting_ai_event,
}
import {
  submit_ai_message
} from "./message_submit"

import {
  setting_edit_event
} from "./message_edit"

import {
  setting_reply_event
} from "./message_reply"

import {
  disp_messages,
  disp_date_hr_for_area
} from "../common/ui"

import {
  translate_from_ja_to_en
} from "../common/translation"

function init_disp_messages(messages) {
  var message_area = $("#message_area");
  message_area.empty();

  // メッセージ表示
  disp_messages(messages, message_area);

  // 時刻の区切り線表示
  disp_date_hr_for_area($("#message_area"));

  // メッセージ編集イベントを設定
  setting_edit_event();

  // リプライボタンイベントを設定
  setting_reply_event();

  setting_translate_event();

  setting_ai_event();
}

function init_pager(messages, pager_info) {

  if (messages.length > 0) {

    if (!pager_info.has_next_message) {
      $("#next_paginate_area").find(".has_histroy").val(false);
      $("#next_paginate_area").hide();
    } else {
      $("#next_paginate_area").find(".has_histroy").val(true);
      $("#next_paginate_area").show();
    }

    if (!pager_info.has_prev_message) {
      $("#prev_paginate_area").find(".has_histroy").val(false);
      $("#prev_paginate_area").hide();
    } else {
      $("#prev_paginate_area").find(".has_histroy").val(true);
      $("#prev_paginate_area").show();
    }

    $("#oldest_message").val(messages[0].id)
    $("#newest_message").val(messages[messages.length - 1].id)
  } else {
    $("#next_paginate_area").find(".has_histroy").val(false);
    $("#next_paginate_area").hide();
    $("#prev_paginate_area").find(".has_histroy").val(false);
    $("#prev_paginate_area").hide();
  }

}

function go_to_top() {
  $('#goto_top a').click(function () {
    $('html,body').animate({
      scrollTop: 0
    }, 400);
    return false;
  });
}

function go_to_bottom() {
  $('#goto_bottom a').click(function () {
    $('html, body').animate({
      scrollTop: $(document).height()
    }, 400);
    return false;
  });
}

function scroll_to_message(message_id) {
  if (!message_id) {
    return;
  }
  var message_dom = '[data-message_id="' + message_id + '"]';
  scroll_to_element(message_dom);
}

function scroll_to_element(selector) {
  if (!selector) {
    return;
  }


  // 要素を取得
  var element = $(selector);
  if (element.length < 1) {
    return;
  }

  // 一旦一番上へ
  window.scrollTo(0, 0);

  // 要素の位置を取得
  var rect = element[0].getBoundingClientRect();
  var x = rect.left;
  var y = rect.top;

  // 取得した要素の位置を引数に指定  
  window.scrollTo(x, y);
}

function scroll_to_reply(select_message_id, select_parent_message_id) {

  var scrolled = false;

  if (select_message_id && select_parent_message_id) {
    var parent_message_dom = get_message_dom(select_parent_message_id);
    $(parent_message_dom).find(".reply_button").trigger('click');
    
    // スクロール先のリプライメッセージの表示エリアが開いてからスクロール
    $(parent_message_dom).find(".reply_area").on('shown.bs.collapse', function (event) {
      if (!scrolled) {
        scrolled = true;
        scroll_to_message(select_message_id);
      }
    });
  }
  
}

function get_message_dom(message_id) {
  if (message_id) {
    var message_content_dom = '[data-message_id="' + message_id + '"]';
    var message_dom = $(message_content_dom).closest("div.message");
    return message_dom
  }
  return null;
}

function get_message_reply_area_dom(message_id) {
  if (message_id) {
    var message_dom = get_message_dom(message_id)
    return message_dom.find(".reply_area");
  }
  return null;
}

function get_message_reply_form_dom(message_id) {
  if (message_id) {
    var message_dom = get_message_dom(message_id)
    return message_dom.find(".reply_form");
  }
  return null;
}

function get_parent_message_dom_from_messages(messages) {
  if (messages.length > 0) {
    var parent_message_id = messages[0].parent_message_id
    return get_parent_message_dom_from_message_id(parent_message_id)
  }
  return null;
}

function get_parent_message_dom_from_message_id(parent_message_id) {
  if (parent_message_id) {
    var selector = '[data-message_id="' + parent_message_id + '"]';
    var parent_message_dom = $(selector).closest("div.message");
    if (parent_message_dom.length > 0) {
      return parent_message_dom
    }
  }
  return null;
}


function setting_translate_event() {
  $(".translate_button").off();
  $(".translate_button").on('click', function(){
    var jaText = $(this).closest(".message_content").find(".message_body").text();
    if (jaText && $(this).closest(".message_content").find(".translate").length <= 0){
      var enText = translate_from_ja_to_en(jaText);
      $(this).closest(".message_content").append("<div class='translate'>【Global translate】" + enText + "</div>")
    }
  });  
}

function setting_ai_event() {
  $(".ai_button").off('click');
  $(".ai_button").on('click', function(){
    var parent_message_dom = $(this).closest(".message_content");
    var parent_message_id = parent_message_dom.data("message_id");

    submit_ai_message(parent_message_id);
  });
}
