import {
  disp_video_alert
} from "./ui";
import {
  is_display
} from "../common/ui";
import {
  Timer
} from "../common/datetime";
import {
  call,
  end_call
} from "./room";
$(function () {

  if (!is_display(".video_chat.student")) {
    return;
  }

  if (!'gon' in window || 'gon' in window && !'token' in gon) {
    disp_video_alert("この講座は有効期限切れです。講座開講先にお問い合わせください。")
    return;
  }
  if ('token' in gon && (!gon.token || gon.token == "")) {
    disp_video_alert("トークンの取得に失敗しました。")
    return;
  }

  $(".make-call").show();
  $(".end-call").hide();


  $('.make-call').on("click", function(){

    $('.reload:visible').popover('show');
    call();
    $(".make-call").hide();
    $(".end-call").show();
  });

  $(".end-call").on("click", function(){
    $('.reload:visible').popover('dispose');
    end_call();
    $(".make-call").show();
    $(".end-call").hide();
    $("#end_lesson_modal").modal({backdrop: 'static'});
  });
  $(document).on('video_chat_invalid', function (e, data) {
    expire(data['message']['message']);
  });
  $(document).on('video_chat_info', function (e, data) {
    expire(data['message']['message']);
  })
  var timer = new Timer(gon.expiration_time, function(){expire("有効期限切れです。")});
  timer.start();
});

function expire (message) {
  end_call();
  $(".make-call").hide();
  $(".end-call").hide();
  location.href = "/lesson_room/invalid?message=" + message;
}

