export {
  check_password_comfirm,
  check_password_require,
  check_weak_pasword,
  check_password_rule,
}
import {
  disp_alert
} from "../common/ui"

var zxcvbn = require('zxcvbn')

$(function () {
  $('form').on('submit', function () {
    $(".alert").alert('close');
  });
})

function check_password_comfirm() {
  $('form').on('submit', function () {
    var password = $("#password").val();
    var password_comfirm = $("#password_confirm").val();

    if (password !== password_comfirm) {
      disp_alert("パスワードと確認用パスワードが相違しています");
      window.scrollTo(0, 0);
      return false;
    }
  });
}

function check_password_require() {
  $('form').on('submit', function () {
    var password = $("#password").val();
    if (!password) {
      disp_alert("パスワードを入力してください");
      window.scrollTo(0, 0);
      return false;
    }
  });
}

function check_password_rule() {
  var minimum_length = 6
  var VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*\d)(?!.*[^a-zA-Z\d\-+=^$*.\[\]{}()?\"!@#%&\/\\\\,><\':;|_~`]).+$/
  $('#password').on('input', function () {
    var password = $("#password").val();
    if (!password) {
      $("#password_invalid_info").hide();
      $("#password_invalid_info").text("");
      $("#password").removeClass("is-invalid");
      return;
    }
    if (password.length < minimum_length || !VALID_PASSWORD_REGEX.test(password)) {
      $("#password_invalid_info").text("半角英字と数字を組み合わせて" + String(minimum_length) + "文字以上で入力してください");
      $("#password_invalid_info").show();
      $("#password").addClass("is-invalid");
      return;
    } else {
      $("#password_invalid_info").hide();
      $("#password").removeClass("is-invalid");
    }
  });

  $('form').on('submit', function () {
    var password = $("#password").val();
    if (!password) {
      return;
    }

    if (password.length < 6 || !VALID_PASSWORD_REGEX.test(password)) {
      disp_alert("パスワードは半角英字と数字を組み合わせて" + String(minimum_length) + "文字以上で入力してください");
      window.scrollTo(0, 0);
      return false;
    }

  });
}

function check_weak_pasword() {

  var too_guessable = 0;
  var very_guessable = 1;
  var somewhat_guessable = 2;
  var safely_unguessable = 3;
  var very_unguessable = 4;

  $('#password').on('input', function () {
    var password = $("#password").val();
    var ok_badge = $('<span class="badge badge-pill badge-success">OK!</span>');
    if (!password) {
      $("#password_meter").removeClass();
      $("#password_meter").text("");
      $("#password_info").hide().find(".card-text").empty();
      return;
    }

    var password_check_result = zxcvbn(password);
    switch (password_check_result.score) {
      case too_guessable:
        $("#password_meter").removeClass();
        $("#password_meter").addClass("too_guessable");
        $("#password_meter").text("パスワードの強さ：とても弱い");
        break;
      case very_guessable:
        $("#password_meter").removeClass();
        $("#password_meter").addClass("very_guessable");
        $("#password_meter").text("パスワードの強さ：弱い");
        break;
      case somewhat_guessable:
        $("#password_meter").removeClass();
        $("#password_meter").addClass("somewhat_guessable");
        $("#password_meter").text("パスワードの強さ：中程度");
        break;
      case safely_unguessable:
        $("#password_meter").removeClass();
        $("#password_meter").addClass("safely_unguessable");
        $("#password_meter").text("パスワードの強さ：強い");
        break;
      case very_unguessable:
        $("#password_meter").removeClass();
        $("#password_meter").addClass("very_unguessable");
        $("#password_meter").text("パスワードの強さ：とても強い");
        break;
      default:
        $("#password_meter").removeClass();
        $("#password_meter").text("");
    }

  });

}