import {
  chatChannel
} from "./chat"
export {
  setting_edit_event
}

function setting_edit_event() {

  $(".edit_button").off();

  $(".edit_button").click(function (e) {

    var message = $(this).closest("div.message")
    var message_content = $(this).closest("div.message_content");
    var message_body = message_content.find(".message_body");
    var message_tags = message_content.find(".message_tags");
    var user = message.find(".user")[0];

    // すでに編集中、または、
    // 自分が送信したメッセージではない場合は編集を拒否
    if (message_content.hasClass("edit") ||
      user.textContent != $("#user").data("user")) {
      return;
    }

    message_body.hide();
    message_tags.hide();
    message_content.addClass("edit"); //editクラスは編集状態であることを表す

    var edit_area = $("<div>").addClass("exit_area");
    var edit_button_area = $("<div>")
    var cancel_button = $("<button>").text("キャンセル").addClass("edit_cancel btn btn-outline-secondary");
    var update_button = $("<button>").text("確定").addClass("update_button btn btn-primary");
    var delete_button = $("<button>").text("削除").addClass("delete_button btn btn-outline-danger");
    var textarea = $("<textarea>").val($(message_body).text());
    var tagarea = $("#message_tag_tmp").clone().attr("id", "edit_tag" + message_content.data("message_id")).addClass("tag_select");

    edit_area.append(tagarea);
    edit_area.append(textarea);
    edit_button_area.append(cancel_button);
    edit_button_area.append(delete_button);
    edit_button_area.append(update_button);
    edit_area.append(edit_button_area);
    message_content.append(edit_area);

    var current_tags = [];
    message_tags.find(".tag").each(function () {
      current_tags.push($(this).data("tag_id"))
    })
    tagarea.val(current_tags).trigger('change').select2({
      width: '100%',
      placeholder: "タグ選択"
    });
    tagarea.next(".select2").find("input").attr("readonly", "true");

    $(cancel_button).click(function () {
      edit_end();
    });

    $(update_button).click(function () {
      message_update();
      edit_end();
    });

    $(delete_button).click(function () {
      delete_alert();
      edit_end();
    });

    function edit_end() {
      edit_area.remove();
      message_content.removeClass("edit");
      message_body.show();
      message_tags.show();
    }

    function message_update() {
      var message_body = textarea.val();
      var message_tags = tagarea.val();
      var message_id = message_content.data("message_id");
      chatChannel.message_update(message_body, message_tags, message_id)
    }

    function message_delete() {
      var message_id = message_content.data("message_id");
      chatChannel.message_delete(message_id);
      $("#modalTemp").modal("hide");
    }

    function delete_alert() {

      var delete_modal = $("#modalTemp");
      delete_modal.find(".modal-title").text("削除確認");

      var body = $("<span>").text("メッセージを削除します。よろしいですか？※削除するとメッセージは復元しません。");
      delete_modal.find(".modal-body").html(body);

      var delete_button = delete_modal.find(".modal-footer").find(".delete-button").text("削除");
      delete_button.removeAttr("hidden");
      delete_button.off();
      delete_button.click(function () {
        message_delete()
      });

      $("#modalTemp").modal();

    }

  });
}