import { Webpush } from "./webpush";
export { ChatWebpush }

class ChatWebpush extends Webpush {
  constructor(serviceWorkerScope, subscribeButtonSelector, course_id) {
    super(serviceWorkerScope, subscribeButtonSelector);
    this.course_id = course_id
  }

  unsubscriptionParams(subscription){
    return [
      ["subscription[course_id]", this.course_id] 
    ]
  }
  subscriptionParams(subscription){
    return [
      ["subscription[course_id]", this.course_id],
      ["subscription[endpoint]", subscription.endpoint],
      ["subscription[auth_key]", subscription.keys.auth],
      ["subscription[p256dh_key]", subscription.keys.p256dh],
    ]
  }

}

