import {
  disp_spinner_button,
  disp_spinner_icon,
  disp_flashing,
  disp_message
} from "../common/ui"

$(function () {

  $("#search_button").click(function(){
    disp_spinner_button($(this), true);
    
  })

  $(document).on('ajax:success', function(event) {
    var data = event.detail[0];
    if(data['mode'] != "search"){
      return;
    }
    var result = data['result'];

    $("#modalTemp").modal();
    $(".modal-body").find(".message").remove();
    $(".modal-body").find(".pagination").remove();
    $(".modal-body").find(".total_count").remove();

    if(result["messages"]){
      result["messages"].forEach(message => disp_message(message, $(".modal-body")));
    } else {
      var total_count_text = "検索結果がありません";
      var total_count = $("<div>").addClass("total_count").append(total_count_text);
      $(".modal-body").prepend(total_count);
    }


    if(result["paginator"]){
      var paginate_area = $(result["paginator"]);
      $(".modal-body").prepend(paginate_area);
    }
    
    disp_spinner_button($("#search_button"), false);
  });

  $(document).on('ajax:error', function(event) {
    console.log("ajax error")
    console.log(event);
    disp_spinner_button($("#search_button"), false);
  });

})