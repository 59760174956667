import consumer from "./consumer"
export {
  videoManagementChannel,
  is_connect
}

var is_connect = false;
var videoManagementChannel = null;

$(function () {
  if ($(".video_chat").length < 1 &&
   $(".chat").length < 1){ return; }

  videoManagementChannel = consumer.subscriptions.create({
    channel: "VideochatManagementChannel",
    channelid: gon.channel_id
  }, {
    initialized() {
      console.log("サブスクリプションが作成されました。" + new Date())
    },
    connected() {
      console.log("接続しました。" + new Date())
      is_connect = true;
    },
    disconnected() {
      console.log("切断されました。" + new Date())
      is_connect = false;
    },
    rejected() {
      console.log("サブスクリプションがサーバーで却下されました。" + new Date())
    },
    invalid() {
      console.log("講座終了" + new Date())
      return this.perform('invalid', {
      });
    },
    expire() {
      console.log("講座有効期限切れを通知します。" + new Date())
      return this.perform('expire', {
      });
    },
    info() {
      console.log("講座のステータスを通知します。" + new Date())
      return this.perform('invalid', {
      });
    },
    received(data) {
  
      var mode = data.mode
  
      if (mode == "expire") {
        console.log("ビデオチャット-有効期限切れ" + new Date());
        $(document).trigger('video_chat_expire', [data]);
      }else if(mode == "invalid"){
        console.log("ビデオチャット-通話終了" + new Date());
        $(document).trigger('video_chat_invalid', [data]);
      }else if(mode == "info"){
        console.log("ビデオチャット-インフォメーション" + new Date());
        $(document).trigger('video_chat_info', [data]);
      } else {
        console.log("不明なモードを受信しました" + new Date());
      }
  
    }
  });


});