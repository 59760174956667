export { Webpush };

class Webpush {
  constructor(serviceWorkerScope, subscribeButtonSelector) {
    this.serviceWorkerScope = serviceWorkerScope; // serviceworkerで登録するスコープ
    this.subscribeButtonSelector = subscribeButtonSelector; // 通知ボタンのセレクタ
  }

  init() {
    // Service Workerが使用できるかチェック
    if (!"serviceWorker" in navigator) {
      // Service Workerがサポートされていない場合、通知機能が非対応であることを示すボタンを表示
      this.updateButtonProps(
        "通知機能非対応のデバイスです。",
        "unsubscribe",
        true
      );
      return;
    }

    // Service Workerの登録
    navigator.serviceWorker.register("/service-worker.js", {
      scope: this.serviceWorkerScope,
    });

    // Service Workerの準備が完了したら
    navigator.serviceWorker.ready.then((registration) => {
      navigator.serviceWorker
        .getRegistration(this.serviceWorkerScope)
        .then((registration) => registration.pushManager.getSubscription())
        .then((subscription) => {
          if (subscription) {
            // 既にsubscriptionがある場合、通知OFFボタンを表示
            this.updateButtonProps("通知OFFにする", "unsubscribe", false);
            return;
          } else {
            // subscriptionがない場合、通知ONボタンを表示
            this.updateButtonProps("通知ONにする", "subscribe", false);
          }
        })
        .catch((error) => {
          // Service Workerの読み取りに失敗した場合のエラーハンドリング
          console.log("service_worker読み取りに失敗しました。" + error);
          // 通知機能が使用できないことを示すボタンを表示
          this.updateButtonProps(
            "通知機能が使用できません。お使いの端末が対応していないか、スマートフォンの場合はホーム画面に追加してご利用ください。",
            "unsubscribe",
            true
          );
        });
    });
  }

  subscribe() {
    // Service Workerの登録を取得
    navigator.serviceWorker
      .getRegistration(this.serviceWorkerScope)
      .then((registration) => {
        // Service Workerの登録に成功した場合
        console.log(
          "servise worker登録に成功しました。 Scope は " + registration.scope
        );
        // Push通知のsubscribeを行う
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: window.vapidPublicKey,
        });
      })
      .then((subscription) => {
        // subscribeに成功した場合
        console.log(
          "pushmanager登録に成功しました。サブスクリプション情報を送信します。"
        );
        // サーバーにsubscription情報を送信
        return this.sendSubscriptionInfo(subscription.toJSON());
      })
      .then(() => {
        // サブスクリプション情報の送信が成功した場合、通知OFFボタンを表示
        this.updateButtonProps("通知OFFにする", "unsubscribe", false);
      })
      .catch((error)  => {
        // Service Workerの登録に失敗した場合
        console.log("登録に失敗しました。" + error);
        throw new Error("登録に失敗しました。" + error);
      });
  }

  // サーバーにsubscription情報を送信
  sendSubscriptionInfo(subscription) {
    fetch("/subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token":
          document
            .querySelector("meta[name=csrf-token]")
            ?.getAttribute("content") ?? "",
      },
      body: new URLSearchParams(this.subscriptionParams(subscription)),
    });
  }

  subscriptionParams(subscription) {
    return [
      ["subscription[endpoint]", subscription.endpoint],
      ["subscription[auth_key]", subscription.keys.auth],
      ["subscription[p256dh_key]", subscription.keys.p256dh],
    ];
  }

  // サーバーにsubscriptionの解除を送信
  sendUnSubscriptionInfo(subscription) {
    fetch("/subscriptions", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token":
          document
            .querySelector("meta[name=csrf-token]")
            ?.getAttribute("content") || "",
      },
      body: new URLSearchParams(this.unsubscriptionParams(subscription)),
    });
  }

  unsubscriptionParams(subscription) {
    return [["subscription[endpoint]", subscription.endpoint]];
  }

  unsubscribe() {
    navigator.serviceWorker
      .getRegistration(this.serviceWorkerScope)
      .then((registration) => {
        if (!registration) {
          // Service Workerが登録されていない場合はエラーをスロー
          throw new Error("Service Worker not registered");
        }
        return registration;
      })
      .then((registration) => registration.pushManager.getSubscription())
      .then((subscription) => {
        if (!subscription) {
          // サブスクリプションが存在しない場合は処理を終了
          return;
        }

        // Push通知のunsubscribeを行い、サーバーに通知
        return subscription
          .unsubscribe()
          .then(() => {
            return this.sendUnSubscriptionInfo(subscription);
          })
          .then(() => {
            // サブスクリプション解除の送信が成功した場合、通知ONボタンを表示
            this.updateButtonProps("通知ONにする", "subscribe", false);
          });
      })
      .catch((error) => {
        // エラーが発生した場合はコンソールにエラーメッセージを出力
        console.error(`Web Push unsubscription failed: ${error}`);
        throw new Error(`Web Push unsubscription failed: ${error}`);
      });
  }

  updateButtonProps(text, action, disabled) {
    let button = $(this.subscribeButtonSelector);

    // ボタンのテキスト、クリックアクション、disabledの設定
    button.prop("disabled", disabled);
    button.text(text);

    // 通知ボタン押下時のアクション変更
    if (action) {
      button.off("click");

      if (action === "subscribe") {
        button.on("click", () => this.subscribe());
      } else if (action === "unsubscribe") {
        button.on("click", () => this.unsubscribe());
      }
    }
  }
}
